// Sentry SDK configuration
// Initialise Sentry first in order to catch any errors including those from loading the rest of the JS
import * as sentry from "./sentry"


const HOTJAR_SITE_ID = JSON.parse(document.querySelector("#HOTJAR_SITE_ID").textContent);
if (HOTJAR_SITE_ID) {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:HOTJAR_SITE_ID,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

// Import all JavaScript libraries used anywhere in the codebase, so that Webpack will
// create a bundle containing all these dependencies
import "jquery";
import "popper.js";
import "bootstrap";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-scroller";
import "datatables.net-scroller-bs4";
import "datatables.net-fixedcolumns";
import "datatables.net-fixedcolumns-bs4";
import "datatables.net-fixedheader";
import "datatables.net-fixedheader-bs4";
import "datatables.net-responsive";
import "datatables.net-responsive-bs4";
import "datatables.net-rowgroup-bs4";
import "sweetalert2";
import "select2";
import "bootstrap-datepicker";
import "django-select2";
import "bootstrap-switch";

import "./utils.js";
import "htmx.org";
import Alpine from "alpinejs";

// Alpine.js initialization
window.Alpine = Alpine
Alpine.start()

sentry.setDatatableErrMode()
