import htmx from "htmx.org";

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
        }
    }
    return cookieValue;
}

let csrftoken = getCookie("csrftoken");

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    },
});

$(".clickable").on("click", function () {
    window.location.href = $(this).data("url");
});

function initialiseDatepickers() {
    $(".datepicker")
        .datepicker({
            format: $("#mainjs").data("datepicker-date-format"),
            autoclose: true,
            calendarWeeks: true,
            clearBtn: true,
            todayHighlight: true,
            weekStart: "1",
        })
        .attr("autocomplete", "off");
}

function reloadAfterHtmx(){
    // reload select2 and datepickers after htmx call
    htmx.onLoad(function(elt) {
        $('.django-select2').djangoSelect2();
        $('select').on('select2:select', function (e) {
            $(this).closest('select').get(0).dispatchEvent(new Event('change'));
        });
        // hack to display form error message on select2 field
        $('#purchaseOrderItems td select.is-invalid + span > span > span.select2-selection')
            .addClass('form-control').addClass('is-invalid');
        initialiseDatepickers();

    });
}

$(document).ready(function () {
    initialiseDatepickers();
    reloadAfterHtmx();
});

function warnBeforeAction(linkURL, obj, action) {
    Swal.fire({
        title: `Are you sure you want to ${action.toLowerCase()} this ${obj}?`,
        text: "",
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: action,
        confirmButtonColor: "#dc3545",
    }).then(function (result) {
        if (result.isConfirmed) {
            window.location.href = linkURL;
        }
    });
}

$(".confirm-delete").click(function (e) {
    e.preventDefault();
    var linkURL = $(this).attr("href");
    var obj = $(this).data("obj");
    warnBeforeAction(linkURL, obj, "Delete");
});

$(document).on('select2:open', (e) => {
    if ($(e.target).attr("multiple") === undefined || !$(e.target).attr("multiple")) {
        if($(".select2-search__field").length === 1)
            document.querySelector('.select2-search__field').focus();
    }
});
